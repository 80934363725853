<template>
  <div class="cmp-configuration-dropdowns">
      <div class="config-main-header">
          <span class="config-step-nr">1</span>
          <h3 v-translation="{ type: 'title', code: 'title_choose_version'}"></h3>
      </div>
      <div class="config-dropdowns-wrapper utlz-row">
        <div class="config-dropdown config-select utlz-col-md-4" v-for="config in configurations" :key="config.id">
            <label>{{config.description}}</label>
            <select v-model="selectedValues[config.id]" v-on:change="generateSelectedProducts(true)">
                <option v-for="product in config.configurationProducts" :key="product.id" :value="product.id">
                    {{product.shortDescription}}
                </option>
            </select>
        </div>
        <div class="config-dropdown config-quantity utlz-col-md-4">
            <label v-translation="{ code: 'label_select_quantity', type: 'label' }"></label>
            <div class="order-quantity">
                <utlz-quantity-buttons
                    :initialQuantity="1"
                    :allowZeroQuantity="false"
                    :quantityFactor="1"
                    @quantityChanged="passConfigQuantity($event)">
                </utlz-quantity-buttons>
            </div>
        </div>
      </div>    
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
export default {
    components: {        
        'utlz-quantity-buttons': QuantityButtons
    },
    data () {
        return {
            selectedValues: {},
            orderQuantity: 1
        }
    },
    computed: {
        ...mapGetters('productDetails', ['configurations', 'productCode'])
    },
    watch: {
        configurations : function (newVal, oldVal) {
            if (newVal.length) {
                var values = this.selectedValues;
                newVal.forEach(elem => {
                    if (!(elem.id in this.selectedValues) || !elem.configurationProducts.find((x) => { return x.id == values[elem.id]})) {
                        this.selectedValues[elem.id] = elem.configurationProducts[0].id;
                    }
                });
                this.generateSelectedProducts(false);
            }
        }
    },
    methods: {
    ...mapActions('productDetails', ['setSelectedConfigurations', 'getConfigurations', 'setConfigurationQuantity']),
    passConfigQuantity( {quantity}) {
        this.setConfigurationQuantity(quantity);
    },
    generateSelectedProducts(doGet) {
        let selectedProducts = [];
        var configs = this.configurations;
        let getValues = {};
        Object.keys(this.selectedValues).forEach(value => {
            let selectedValue = this.selectedValues[value];
            let config =  configs.find(elem => { 
                return elem.id === value.toString()
            });
            let product = config.configurationProducts.find(prod => prod.id === selectedValue);
            
            let selectedProduct = {
                configurationId: value,
                productCode: product.id,
                saleUnit: product.saleUnit <= 1 ? 1 : product.saleUnit,
                originalSaleUnit: product.saleUnit <= 1 ? 1 : product.saleUnit,
                product: product,
                order: config.order
            }
            getValues[config.order] = product.id;
            selectedProducts.push(selectedProduct);
        });
        this.setSelectedConfigurations(selectedProducts);

        if (doGet) {
            this.getConfigurations({productCode: this.productCode, selectedValues: getValues});
        }

    }
  }
}
</script>