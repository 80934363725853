<template>
  <div class="cmp-configurations-cart utlz-reset custom-scrollbar" v-if="render">

    <transition name="fadein">
      <utlz-static-modal v-if="orderInProgress">
        <template v-slot:static-modal-content>
          <div class="delete-orderlines-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_adding_orderlines' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>


    <i class="fa fa-caret-left" aria-hidden="true"></i>
    <template v-if="screenWidth > 991">
      <h3 v-translation="{ type: 'title', code: 'title_config_overview' }"></h3>
      <ul>
        <li v-for="config in selectedConfiguration" :key="config.id" class="cart-line">
          <span class="quantity">{{ config.saleUnit }}x</span>
          <span class="short-desc">{{ config.product.shortDescription }}</span>
          <template v-if="showPrices">
            <span class="price">{{ formatPrices(config.product.prices[0].rawPrice * config.saleUnit) }}</span>
          </template>
        </li>
        <li v-for="accessory in selectedAccessories" :key="accessory.id" class="cart-line">
          <span class="quantity">{{ accessory.saleUnit }}x</span>
          <span class="short-desc">{{ accessory.product.shortDescription }}</span>
          <template v-if="showPrices">
            <span class="price">{{ formatPrices(accessory.product.prices[0].rawPrice * accessory.saleUnit) }}</span>
          </template>
        </li>
      </ul>
      <div class="cart-line-bottom">
        
        <template v-if="showPrices">
          <div class="totals">
            <span v-translation="{ type: 'title', code: 'title_config_totals' }"></span>
            <span>{{ totalPrice }}</span>
          </div>
          <a @click="orderSelectedProducts" class="clickthrough-btn">
            <i class="material-icons-outlined">shopping_cart</i>
            <span v-translation="{ type: 'button', code: 'button_add_to_cart' }"></span>
          </a>    
        </template>

        <template v-else>
          <a :href="loginUrl" class="clickthrough-btn">
            <span v-translation="{ type: 'button', code: 'button_login_to_order' }"></span>
          </a>   
        </template>

      </div>
    </template>
    <template v-else>
      <div class="mobile-cart">
        <div class="totals">
          <h3 v-translation="{ type: 'title', code: 'title_config_overview' }"></h3>
          <div class="total-products">
            <span class="label" v-translation="{ type: 'title', code: 'title_config_prod_total' }"></span>
            <span class="value">{{ configItemTotals }}</span>
          </div>
          <div class="total-price">
            <span class="label" v-translation="{ type: 'title', code: 'title_config_totals' }"></span>
            <span class="value">{{ totalPrice }}</span>
          </div>
        </div>
        <template v-if="showPrices">
          <a @click="orderSelectedProducts" class="clickthrough-btn">
            <i class="material-icons-outlined">shopping_cart</i>
            <span v-translation="{ type: 'button', code: 'button_add_to_cart' }"></span>
          </a>    
        </template>
        <template v-else>
          <a :href="loginUrl" class="clickthrough-btn">
            <span v-translation="{ type: 'button', code: 'button_login_to_order' }"></span>
          </a>   
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from 'src/utilities/helpers';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
export default {
  components: {    
    'utlz-static-modal': StaticModal,
  },
  data () {
    return {      
      setStickyDesktop: false,
      desktopPosRight: 0,
      controlWidth: 0,
      render: true
    };
  },
  computed: {
    ...mapGetters(['screenWidth', 'showPrices', 'language']),
    ...mapGetters('productDetails', ['getConfigTotals', 'configItemTotals', 'selectedAccessories', 'selectedConfiguration', 'orderInProgress']),    
    totalPrice () {
      return formatPrice(this.getConfigTotals);
    },
    loginUrl() {
      return `/${this.language}/webshop/login.aspx`;
    }
  },
  watch: {
    selectedAccessories(newval, oldval){
      this.render = false;
      this.$nextTick(() => {
          this.render = true;
        });
    }
  },
  methods: {
    ...mapActions('productDetails', ['orderSelectedProducts']),
    formatPrices (price) {
      return formatPrice(price);
    },
    handleScroll () {
      if (this.screenWidth > 991) {        
        let desktopAnchor = document.getElementById('accessories-cart-column');
        if (desktopAnchor) {
          let positions = desktopAnchor.getBoundingClientRect();
          if (positions.top < 80) {
            $(desktopAnchor).addClass('sticky');
            this.desktopPosRight = positions.left;
            this.controlWidth = positions.width;
            $('#accessories-cart-column > div').css({
                width: this.controlWidth,
                left: this.desktopPosRight
            });
          } else {  
            $(desktopAnchor).removeClass('sticky');        
          }
        }        
      } else {        
        let mobileAnchor = document.getElementById('accessories-mobile-anchor');
        if (mobileAnchor) {
          $('#accessories-cart-column > div').css({
              width: '100%',
              left: 0
          });
          let positions = mobileAnchor.getBoundingClientRect();
          if (positions.top < $(window).height()) {
            $('.cmp-configurations-cart').addClass('sticky');
          } else {
            $('.cmp-configurations-cart').removeClass('sticky');
          }
        }        
      }
    }
  },
  updated () {
    this.handleScroll();
  },
  created() {
    $(window).scroll(()=> {    
      this.handleScroll();
    });
  }
}
</script>
