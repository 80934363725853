<template>
  <div class="cmp-shopping-cart-control utlz-reset">

    <!------------- BESPOKE HWS: Added --------------->
    <transition name="fadein">
      <utlz-static-modal v-if="deleteOrderLoader">
        <template v-slot:static-modal-content>
          <div class="delete-orderlines-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_deleting_orderlines' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>
    <!------------ END BESPOKE HWS ----------->

    <!-- BESPOKE HWS: Added .shopping-cart-btn and .header-icon-btn -->
    <div class="icon-wrapper shopping-cart-btn header-icon-btn" @click="toggleCart()">
      <!-- BESPOKE HWS -->
      <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
      <span class="material-icons-outlined">shopping_cart</span>
      <span class="btn-label" v-translation="{ type: 'label', code: 'label_header_shoppingcart' }"></span>
      <!-- END BESPOKE HWS -->
      <span class="total-counter btn-u">{{ totalProductsCount }}</span>
    </div>

    <transition name="fadein-cart">
      <div v-if="cartOpen">

        <div @click="toggleCart()" class="close-target"></div>

        <div class="shopping-cart-panel">

          <span class="close-button close-panel-mobile" @click="toggleCart()">
            <i class="fa fa-close" aria-hidden="true"></i>
          </span>

          <div class="panel-header flex-row-nowrap align-baseline">
            <!-- BESPOKE HWS-->
            <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
            <!-- END BESPOKE HWS -->
            <h2>
              <span v-translation="{ type: 'title', code: 'title_shopping_cart_header' }"></span>
              <span class="counter">({{ totalProductsCount }})</span>
            </h2>
          </div>

          <div v-if="loadingOrderLines">
            <div v-for="index in 4" :key="index" class="ph-silhouette utlz-row">
              <span class="silhouette-img utlz-col-2"></span>
              <div class="ph-paragraph utlz-col-10">
                <span class="silhouette-line"></span>
                <span class="silhouette-line"></span>
              </div>
            </div>
          </div>

          <template v-if="showOrderLines">
            <ul class="order-lines">
              <li v-for="(orderLine, index) in bespokeOrderLines" :key="orderLine.lineId + index" class="orderline-row flex-row-nowrap">
                <div v-if="!hideShoppingCartImages" class="prod-img">
                  <img :src="orderLine.product.imageUrl ? orderLine.product.imageUrl : '/documents/productimages/not-available-small.jpg'" />
                </div>
                <div class="prod-desc">
                  <a :href="orderLine.product.targetUrl">
                    <h3 class="prod-title header-small">{{ orderLine.product.shortDescription }}</h3>
                  </a>
                  <!-- BESPOKE HWS: Grouped quantity. price and underlying products -->
                  <div class="quantity-wrapper">   
                    <template v-if="!orderLine.containsProducts">                                     
                      <span class="quantity">{{ orderLine.computedQuantity }} x</span>
                      <utlz-product-price
                        :prices="orderLine.product.prices[0]"
                        v-if="orderLine.product.prices.length"
                      ></utlz-product-price>
                    </template>
                    <div class="contains-products" v-if="orderLine.containsProducts">
                      <span>{{orderLine.containsProducts}}</span>
                      <span v-translation="{ type: 'label', code: 'label_cart_contains' }">Contains:</span>
                    </div>
                  </div>
                  <!-- END BESPOKE HWS -->
                </div>
                
                <div class="delete">
                  <span class="close-button small grey" @click="customDelete(orderLine)">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                </div>
              </li>
            </ul>
          </template>

          <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
            <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
          </div>

          <div class="fade-white"></div>

          <utlz-shopping-cart-totals v-if="showOrderLines" :format="shoppingCartControlTotalsFormat"></utlz-shopping-cart-totals>

          <div v-if="showOrderLines" class="actions">
            <a :href="shoppingCartUrl" class="button theme-primary block">
              <i class="uws-icon uws-cart" aria-hidden="true"></i>
              <span v-translation="{ type: 'button', code: 'button_view_shopping_cart' }"></span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import { OrderLine } from 'src/models/order-line.model';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
// BESPOKE HWS
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
// END BESPOKE HWS
export default {
  components: {
    'utlz-shopping-cart-totals': ShoppingCartTotals,
    'utlz-product-price': ProductPrice,
    // BESPOKE HWS    
    'utlz-static-modal': StaticModal
    // END BESPOKE HWS
  },
  data () {
    return {
      cartOpen: false
    }
  },
  computed: {
    // BESPOKE HWS: Added orderNotLoggedOn, should been added in a future release of the standard
    ...mapGetters(['language', 'hideShoppingCartImages','userLoggedOn', 'orderNotLoggedOn']),
    // END BESPOKE HWS
    // BESPOKE HWS
    // Added deleteOrderLoader to mapGetters
    ...mapGetters('shoppingCart', ['orderLines', 'loadingOrderLines', 'totalProductsCount', 'shoppingCartEmpty', 'shoppingCartControlTotalsFormat', 'groupedMainProducts', 'groupedProducts', 'deleteOrderLoader']),
    // END BESPOKE HWS

    // BESPOKE HWS
    // Load in different url's depending on webshop setting, shoudl be included in a future fix of the standard
    shoppingCartUrl () {
      if (this.orderNotLoggedOn && !this.userLoggedOn) {        
        return `/${this.language}/webshop/paymentprocess/shopping_cart.aspx`;
      } else {      
        return `/${this.language}/webshop/paymentprocess/single_checkout.aspx`;
      }
    },
    // END BESPOKE HWS
    showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    //BESPOKE
    bespokeOrderLines() {
      var lines = this.orderLines.filter(x => { return !x.customFields.correlationId });

      if (Object.keys(this.groupedMainProducts).length) {
        Object.keys(this.groupedMainProducts).forEach(x => {
          var newLine = new OrderLine(null);

          let total = 0;

          if (this.groupedProducts[x] !== undefined) {
            this.groupedProducts[x].forEach(element => {
            if (element.product.prices[0]) {
                total += element.totals.rawPrice;
              }
            });

            newLine.product = this.groupedMainProducts[x];
            newLine.totals.rawPrice = total;
            newLine.quantity = 1;
            newLine.containsProducts = this.groupedProducts[x].length +1;
            newLine.productGroup = x;

            lines.push(newLine);
          }                  
        });
      }
      return lines;
    }
  },
  methods: {
    ...mapActions('shoppingCart', ['initShoppingCart', 'getShoppingCartTotalAndCount', 'deleteOrderLine', 'deleteProducts']),
    toggleCart () {
      this.cartOpen = !this.cartOpen;
      if (this.cartOpen && !window.vue.modules.shoppingCartOverview) {
        this.initShoppingCart();
      }
    },
    customDelete(orderLine) {
      if (orderLine.containsProducts) {
        this.deleteProducts(orderLine.productGroup);
      } else {
        this.deleteOrderLine(orderLine.lineId);
      }
    }
  },
  created () {
    this.getShoppingCartTotalAndCount();
  }
};
</script>

<style>
</style>
