<template>
  <div class="cmp-shopping-cart-overview utlz-reset utlz-row" :class="tableClasses">

    <!------------- BESPOKE HWS: Added --------------->
    <transition name="fadein">
      <utlz-static-modal v-if="deleteOrderLoader">
        <template v-slot:static-modal-content>
          <div class="delete-orderlines-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_deleting_orderlines' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>
    <!------------ END BESPOKE HWS ----------->

    <!---------- BESPOKE HWS: Added prompt to delete configured product ------->
    <div class="delete-blocker" v-if="deleteOrderLoader"> 
      Test
    </div>
    <transition name="fadein">
      <utlz-prompt
        v-if="showConfigPrompt"
        :payload="configProductPrompt.lineId"
        @confirm="deleteConfigProduct(configProductPrompt)"
        @cancel="cancelConfigPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="delete-config-prompt utlz-row">
          <div class="utlz-col-12">
            <h2 v-translation="{ type: 'title', code: 'title_delete_config' }"></h2>            
          </div>
          <div class="utlz-col-3 img">
            <img :src="groupedMainProducts[configProductPrompt].imageUrl">
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ groupedMainProducts[configProductPrompt].shortDescription }}
            </h3>            
            <div v-html="configProductMessage"></div>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>
    <!---------------------- END BESPOKE HWS ---------------------------->

    <!-----------------------BESPOKE HWS: Added product modal --------------------------------->
    <utlz-static-modal v-if="showSpecsModal">
      <template v-slot:static-modal-content>
        <div class="product-specs-modal utlz-row">
          
          <span class="close-button">
            <i class="fa fa-times-circle" aria-hidden="true" @click="toggleSpecsModal()"></i>
          </span>

          <div class="utlz-col-12 flex-column align-center just-center">                          
            <div class="modal-header">
              <h3 v-translation="{ type: 'title', code: 'title_specifications' }"></h3>
            </div>

            <div class="modal-inner">

              <div class="prod-desc">
                <img :src="modalProduct.imageUrl ? modalProduct.imageUrl : '/documents/productimages/not-available-large.jpg'" />
                <div class="text-container">
                  <h3>{{ modalProduct.shortDescription }}</h3>
                  <div v-html="modalProduct.longDescription"></div>
                  <div class="spec-list">                                   
                    <div class="spec-item">
                      <div v-translation="{ code: 'label_product_code', type: 'label' }" class="spec-desc">
                      </div>
                      <div class="spec-value">
                        {{ modalProduct.id }}
                      </div>
                    </div>

                    <div class="spec-item" v-for="property in Object.keys(modalProduct.properties)" :key="property">
                      <div class="spec-desc">
                        <span>
                        {{ property }}
                        </span>
                      </div>
                      <div class="spec-value">
                        <span>
                        {{ modalProduct.properties[property] }}
                        </span>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>                

            </div>
          </div>
        </div>
      </template>
    </utlz-static-modal>
    <!------------------------------- END BESPOKE HWS --------------------------------->

    <transition name="fadein">
      <utlz-prompt
        v-if="showDeleteProductPrompt"
        :payload="promptPayload.lineId"
        @confirm="deleteOrderLineFromCart($event)"
        @cancel="cancelPrompt()"
      >

      <template v-slot:prompt-content>
        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_from_cart' }"></span>
            </h2>
          </div>
          <div class="utlz-col-3 img">
            <img :src="promptPayload.product.imageUrl">
          </div>
          <div class="utlz-col-9">
            <h3>
              {{ promptPayload.product.shortDescription }}
            </h3>
          </div>
        </div>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-prompt
        v-if="showEmptyCartPrompt"
        @confirm="emptyCart()"
        @cancel="cancelEmptyCartPrompt()"
      >

      <template v-slot:prompt-content>
        <h2 v-translation="{ type: 'text', code: 'txt_confirm_clear_shopping' }"></h2>
      </template>

      </utlz-prompt>
    </transition>

    <transition name="fadein">
      <utlz-static-modal v-if="validatingStock">
        <template v-slot:static-modal-content>
          <div class="validate-stock-modal utlz-row">
            <div class="utlz-col-12 flex-row-nowrap align-center just-center">
              <span class="loader"></span>
              <h2>
                <span v-translation="{ type: 'label', code: 'label_validating_stock' }"></span>
              </h2>
            </div>
          </div>
        </template>
      </utlz-static-modal>
    </transition>

    <transition-group name="fade" tag="div" class="ph-wrapper utlz-col-12">
      <template v-if="loadingOrderLines">
        <div v-for="index in 4" :key="index" class="ph-silhouette orderlist-row">
          <span class="silhouette-img"></span>
          <div class="ph-paragraph">
            <span class="silhouette-line"></span>
            <span class="silhouette-line"></span>
          </div>
        </div>
      </template>
    </transition-group>

    <div v-if="shoppingCartEmpty" class="empty-cart-message col-md-12">
      <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_empty' }"></h2>
    </div>

    <!-- BESPOKE HWS: Disabled default header -->
    <!-- <transition name="fade">
      <div v-if="showOrderLines && screenWidth >= 880" class="orderline-header utlz-col-md-12">
        <div class="orderlines-wrapper">
          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell"></div>
          <div class="prod-desc orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>
          <div class="prod-price orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>

          <div class="prod-stock orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
          </div>

          <div class="order-product orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_quantity' }"></span>
          </div>

          <div class="prod-total orderline-cell">
            <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>
          <div class="delete-prod orderline-cell">
          </div>
        </div>
      </div>
    </transition> -->
    <!-- END BESPOKE HWS -->

    <transition-group name="fade" tag="div" class="orderline-table utlz-col-md-12">
      <template v-if="showOrderLines && !deleteOrderLoader">
        
        <div class="grouped-product" v-for="productGroup in Object.keys(groupedProducts)" :key="productGroup">

          <div class="grouped-product-header orderline-row">
            
            <div class="prod-img orderline-cell">
              <a :href="groupedMainProducts[productGroup].targetUrl">
                <img :src="groupedMainProducts[productGroup].imageUrl ? groupedMainProducts[productGroup].imageUrl : '/documents/productimages/not-available-small.jpg'" />
              </a>
            </div>

            <div class="prod-desc">
              <h3>{{ groupedMainProducts[productGroup].shortDescription }} <span class="config-total-count "> x {{ getParentQuantity(groupedProducts[productGroup][0]) }}</span></h3>              
              <span class="material-icons-outlined">expand_more</span>
            </div>

            <template v-if="screenWidth >= 880">
              <div class="more-info">
                <span v-translation="{ type: 'label', code: 'label_config_moreinfo' }"></span>
              </div>

              <div class="del-time">
                <span v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
              </div>

              <div class="prod-price">
                <span v-translation="{ type: 'label', code: 'label_config_price' }"></span>
              </div>

              <div class="order-product">
                <span v-translation="{ type: 'label', code: 'label_config_qty' }"></span>
              </div>

              <div class="prod-total">
                <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
              </div>              
            </template>

            <div class="delete-prod orderline-cell">
                <span class="close-button small grey" @click="promptDeleteConfig(productGroup)">
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </span>
              </div>
          </div>

          <div class="included-products">
            <div class="product orderline-row" v-for="orderLine in groupedProducts[productGroup]" :key="orderLine.lineId">
              <div class="prod-img orderline-cell">
                <a :href="orderLine.product.targetUrl">
                  <img :src="orderLine.product.imageUrl ? orderLine.product.imageUrl : '/documents/productimages/not-available-small.jpg'" />
                </a>
              </div>
              <div class="prod-desc orderline-cell">
                <a :href="orderLine.product.targetUrl">
                  <span class="prod-code">{{ orderLine.product.id }}</span>
                  <span class="prod-title">{{ orderLine.product.shortDescription }}</span>
                </a>
              </div>
              <div class="more-info">
                <span @click="toggleSpecsModal(orderLine.product)" class="material-icons-outlined">info</span>
              </div>
              <div class="del-time orderline-cell">
                <div class="del-time orderline-cell">
                  <span class="mobile-label" v-if="screenWidth < 879" v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
                  <span v-if="orderLine.product.deliveryTime.description !== ''">{{ orderLine.product.deliveryTime.description }}</span>
                </div>
              </div>
              <!-- BESPOKE HWS-88: Wrapped prices in template with showPrices -->
              <template v-if="showPrices">
                <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
                  <utlz-product-price
                    :prices="orderLine.product.prices[0]"
                  ></utlz-product-price>
                </div>
                <div v-else class="stock-status-wrapper text-placeholder pulse">
                  <span class="loader"></span>
                </div>
              </template>
              <!-- END BESPOKE HWS-88-->
              
              <div class="order-product orderline-cell">{{ orderLine.quantity }}</div>
              <div class="prod-total orderline-cell">{{ orderLine.totals.price }}</div>
              <div class="delete-prod orderline-cell">
                  
              </div>
            </div>
          </div>
        </div>    

        <!-- BESPOKE HWS -->
        <div v-if="screenWidth >= 880 && showStandardHeader" class="grouped-product-header orderline-row" :key="'standard-header'">              
          <div class="prod-img orderline-cell">            
          </div>
          <div class="prod-desc">
            <span v-translation="{ type: 'label', code: 'label_header_prod_desc' }"></span>
          </div>
          <div class="more-info">
            <span v-translation="{ type: 'label', code: 'label_config_moreinfo' }"></span>
          </div>
          <div class="del-time">
            <span v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
          </div>
          <div class="prod-price">
            <span v-translation="{ type: 'label', code: 'label_config_price' }"></span>
          </div>
          <div class="order-product">
            <span v-translation="{ type: 'label', code: 'label_config_qty' }"></span>
          </div>
          <div class="prod-total">
            <span v-translation="{ type: 'label', code: 'label_header_prod_total' }"></span>
          </div>

          <div class="delete-prod orderline-cell">
          </div>
        </div>
        <!-- END BESPOKE HWS -->

        <!-- BESPOKE HWS: Added class standard-lines -->
        <div v-for="orderLine in orderLines.filter(x => { return !x.customFields.correlationId })" :key="orderLine.lineId" class="orderline-row standard-lines">
          <!-- END BESPOKE HWS -->

          <div v-if="!hideShoppingCartImages" class="prod-img orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <img :src="orderLine.product.imageUrl" />
            </a>
          </div>

          <div class="prod-desc orderline-cell">
            <a :href="orderLine.product.targetUrl">
              <h3 class="prod-title body-text">{{ orderLine.product.shortDescription }}</h3>
              <span class="prod-code">{{ orderLine.product.id }}</span>
            </a>

            <template v-if="useOrderLineComments && screenWidth >= 880">
              <utlz-order-line-comment
                :orderLine="orderLine"
                ></utlz-order-line-comment>
            </template>
          </div>

          <!-- BESPOKE HWS: Added more info and delivery time in default order lines -->
          <div class="more-info">
            <span @click="toggleSpecsModal(orderLine.product)" class="material-icons-outlined">info</span>
          </div>

          <div class="del-time orderline-cell">
            <span class="mobile-label" v-if="screenWidth < 879" v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
            <span v-if="orderLine.product.deliveryTime.description !== ''">{{ orderLine.product.deliveryTime.description }}</span>
          </div>
          <!-- END BESPOKE HWS -->

          <!-- BESPOKE HWS-88: Wrapped prices in template with showPrices -->
          <template v-if="showPrices">
            <div class="prod-price orderline-cell" v-if="orderLine.product.prices.length">
              <utlz-product-price
                :prices="orderLine.product.prices[0]"
              ></utlz-product-price>
            </div>
            <div v-else class="stock-status-wrapper text-placeholder pulse">
              <span class="loader"></span>
            </div>
          </template>
          <template v-else>
             <div class="prod-price orderline-cell"></div>
          </template>
          <!-- END BESPOKE HWS-88 -->

          <!-- BESPOKE HWS: Disable stock, since we use an estimated delivery time field -->
          <!-- <div class="prod-stock orderline-cell">
            <utlz-product-stock
              :stock="orderLine.product.stock"
              :stockTotal="orderLine.product.stock"
            ></utlz-product-stock>
          </div> -->
          <!-- END BESPOKE HWS -->

          <div class="order-product orderline-cell">
            <span v-if="loading" class="loader"></span>
            <utlz-order-product
              v-if="orderLine.product.stock !== null"
              :product="orderLine.product"
              :initialQuantity="orderLine.quantity"
              :initialUnitCode="orderLine.unitCode"
              :useOrderMode="false"
              :totalOrderLinesQuantity="orderLine.totalOrderLinesQuantity"
              @quantityChanged="quantityChanged($event, orderLine)"
              @unitsChanged="unitsChanged($event, orderLine)"
            ></utlz-order-product>
          </div>

          <div class="prod-total orderline-cell">
            <span>{{ orderLine.totals.price }}</span>
          </div>

          <div class="delete-prod orderline-cell">
            <span class="close-button small grey" @click="promptDelete(orderLine)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
          </div>

          <template v-if="useOrderLineComments && screenWidth < 880">
            <div class="mobile-order-comment">
              <utlz-order-line-comment
                :orderLine="orderLine"
              ></utlz-order-line-comment>
            </div>
          </template>

        </div>
      </template>      

    </transition-group>

    <template v-if="showEmptyShoppingCart && !shoppingCartEmpty && !loadingOrderLines">
      <div class="utlz-col-md-12 clear-cart-col">
        <a @click="showEmptyCartPrompt = true" v-translation="{ type: 'button', code: 'btn_clear_shopping_cart' }" class="button small outline clear-cart"></a>
      </div>
    </template>

    <div id="stock-warning" class="utlz-col-md-6">
      <transition name="fade">
        <template v-if="invalidOrderLines.length">
          <div class="stock-warning">
            <div class="stock-warning-wrapper">
              <h2 v-translation="{ type: 'title', code: 'title_shopping_cart_stock_warning' }"></h2>
              <ul>
                <li v-for="orderLine in invalidOrderLines" :key="orderLine.lineId">
                  <i class="fa fa-caret-right" aria-hidden="true"></i><span>{{ orderLine.product.shortDescription }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </transition>

      <utlz-coupons v-if="useCoupons && showOrderLines"></utlz-coupons>

    </div>

    <div class="utlz-col-md-6">
      <transition name="fade">
        <utlz-shopping-cart-totals
          v-if="showOrderLines"
          :format="shoppingCartTotalsFormat"
        ></utlz-shopping-cart-totals>
      </transition>

      <!-- BESPOKE HWS -->
      <div v-if="greatestDeliveryTime && showOrderLines" class="estimated-del">
        <span class="material-icons-outlined">info</span>
        <span v-translation="{ type: 'label', code: 'label_estimated_delivery' }"></span>
        <span class="date-value">{{ greatestDeliveryTime }}</span>
      </div>
      <!-- END BESPOKE HWS -->
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Prompt from 'src/components/ui/prompt/Prompt.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import Coupons from 'src/components/webshop/coupons/Coupons.vue';
import OrderLineComment from 'src/components/webshop/order-product/blocks/OrderLineComment.vue';
import ShoppingCartTotals from 'src/components/webshop/shopping-cart/ShoppingCartTotals.vue';
import _ from 'lodash';
export default {
  components: {
    'utlz-prompt': Prompt,
    'utlz-static-modal': StaticModal,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-coupons': Coupons,
    'utlz-order-line-comment': OrderLineComment,
    'utlz-shopping-cart-totals': ShoppingCartTotals
  },
  data () {
    return {
      loading: false,
      showDeleteProductPrompt: false,
      promptPayload: null,
      showEmptyCartPrompt: false,
      // BESPOKE HWS
      showConfigPrompt: false,
      configProductPrompt: null,
      configProductMessage: "",
      showSpecsModal: false,
      modalProduct: {}
      // END BESPOKE HWS
    }
  },
  computed: {
    // BESPOKE HWS
    // Added deleteOrderLoader to mapGetters

    // BESPOKE HWS
    // Added showPrices
    ...mapGetters(['screenWidth', 'showStock', 'useCoupons', 'useOrderLineComments', 'useMinimumOrderQuantity', 'hideShoppingCartImages', 'showEmptyShoppingCart', 'showPrices']),
    // END BESPOKE HWS
    ...mapGetters('shoppingCart', [
      'orderLines', 
      'invalidOrderLines', 
      'loadingOrderLines', 
      'orderLinesTotal', 
      'minimumOrderQuantity', 
      'shoppingCartEmpty', 
      'shoppingCartTotalsFormat', 
      'validatingStock', 
      'groupedMainProducts', 
      'groupedProducts', 
      'deleteOrderLoader', 
      'deleteOrderLoader',
      'greatestDeliveryTime'
      ]),
    // END BESPOKE HWS
    showOrderLines () {
      return this.orderLines.length > 0 && this.orderLines[0].product !== null;
    },
    tableClasses () {
      let classes = '';
      if (!this.showStock) {
        classes += 'hide-stock ';
      }
      if (this.hideShoppingCartImages) {
        classes += 'hide-images ';
      }
      return classes;
    },
    getTotalPriceForGroup() {
      var obj = {};
      Object.keys(this.groupedProducts).forEach(x => {
        let total = 0;

        this.groupedProducts[x].forEach(element => {
          if (element.product.prices[0]) {
            total += element.totals.rawPrice;
          }
        });

        obj[x] = total;
      });
      return obj;
    },
    // BESPOKE HWS
    showStandardHeader () {
      return Object.keys(this.groupedMainProducts).length === 0;
    }
    // END BESPOKE HWS
  },
  methods: {
    ...mapActions('shoppingCart', ['initShoppingCart', 'updateOrderLineQuantity', 'deleteOrderLine', 'emptyShoppingCart', 'deleteProducts']),
    quantityChanged ({ computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: orderLine.unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, orderLine) {
      const payload = {
        Comments: orderLine.comments,
        ProductId: orderLine.productId,
        LineId: orderLine.lineId,
        Quantity: computedQuantity.toString(),
        UnitCode: unitCode,
        IsFree: orderLine.isFree
      };
      this.updateOrderLineQuantity(payload).then(res => {
      });
    },
    promptDelete (orderLine) {
      this.showDeleteProductPrompt = true;
      this.promptPayload = orderLine;
    },
    cancelPrompt () {
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    deleteOrderLineFromCart (orderLineId) {
      this.deleteOrderLine(orderLineId);
      this.showDeleteProductPrompt = false;
      this.promptPayload = null;
    },
    cancelEmptyCartPrompt () {
      this.showEmptyCartPrompt = false;
    },
    emptyCart () {
      this.showEmptyCartPrompt = false;
      this.emptyShoppingCart();
    },
    // BESPOKE HWS
    promptDeleteConfig (productId) {      
      this.configProductPrompt = productId; 
      let message = window.vue.translations.text.text_delete_config;
      this.configProductMessage = message.replace('[1]', '<span class="config-counter">' + this.groupedProducts[productId].length + '</span>');
      this.showConfigPrompt = true;
    },
    cancelConfigPrompt () {      
      this.showConfigPrompt = false;
    },
    deleteConfigProduct (groupId) {
      this.showConfigPrompt = false;
      this.deleteProducts(groupId);
    },
    toggleSpecsModal (product) {
      if (this.showSpecsModal) {
        this.modalProduct = {};
      } else {
        this.modalProduct = product;
      }
      this.showSpecsModal = !this.showSpecsModal;
    },
    getParentQuantity(product) {
      let quantity = product.customFields.parentQuantity;
      if (quantity == undefined) { 
        quantity = 1; 
      }
      return quantity;
    }
    // END BESPOKE HWS
  },
  created () {
    this.initShoppingCart();
    this.quantityChanged = _.debounce(this.quantityChanged, 500);
  }
}
</script>

<style>

</style>
