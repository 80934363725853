<template>
  <div class="cmp-accessories" v-if="render && accessories.length">

      <!------------------------------- MODAL --------------------------------->
      <utlz-static-modal v-if="showSpecsModal">
        <template v-slot:static-modal-content>
          <div class="product-specs-modal utlz-row">
            
            <span class="close-button">
              <i class="fa fa-times-circle" aria-hidden="true" @click="toggleSpecsModal()"></i>
            </span>

            <div class="utlz-col-12 flex-column align-center just-center">                          
              <div class="modal-header">
                <h3 v-translation="{ type: 'title', code: 'title_specifications' }"></h3>
              </div>

              <div class="modal-inner">

                <div class="prod-desc">
                  <img :src="modalProduct.imageUrl ? modalProduct.imageUrl : '/documents/productimages/not-available-large.jpg'" />
                  <div class="text-container">
                    <h3>{{ modalProduct.shortDescription }}</h3>
                    <div v-html="modalProduct.longDescription"></div>
                    <div class="spec-list">                                   
                      <div class="spec-item">
                        <div v-translation="{ code: 'label_product_code', type: 'label' }" class="spec-desc">
                        </div>
                        <div class="spec-value">
                          {{ modalProduct.id }}
                        </div>
                      </div>

                      <div class="spec-item" v-for="(key, index) in Object.keys(modalProduct.properties)" :key="'modal-prop-' + index">
                        <div class="spec-desc">               
                          {{ key }}           
                        </div>
                        <div class="spec-value">                          
                          {{ modalProduct.properties[key] }}
                        </div>
                      </div>                  
                    </div>
                  </div>
                </div>                

              </div>
            </div>
          </div>
        </template>
      </utlz-static-modal>
      <!------------------------------- END MODAL --------------------------------->


      <div id="cart-anchor"></div> 
      
      <div class="config-main-header">
          <span class="config-step-nr">2</span>
          <h3 v-translation="{ type: 'title', code: 'title_choose_access'}"></h3>
      </div>
      <div class="accessory-slot-wrapper">
        <div class="accessory-slot" v-for="accessory in accessories" :key="accessory.id">
          <div class="accessory-title-wrapper" v-if="screenWidth >= 768">          
            <div class="col-desc">
              <h4 class="accessory-title">{{ accessory.label }}</h4>
            </div>
            <div class="col-more-info">
              <span v-translation="{ type: 'label', code: 'label_config_moreinfo' }"></span>
            </div>
            <div class="col-delivery">
              <span v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
            </div>
            <div class="col-price">
              <span v-translation="{ type: 'label', code: 'label_config_price' }"></span>
            </div>
            <div class="col-quantity">
              <span v-translation="{ type: 'label', code: 'label_config_qty' }"></span>
            </div>
            <div class="col-cart">
              <span class="material-icons-outlined">add_shopping_cart</span>
            </div>
          </div>

          <div class="option-group-wrapper">
            <div class="option-group select-option" v-for="(optionGroup, index) in accessory.optionGroups" :key="optionGroup.groupId">
              <template v-if="index === 0">
                <h4 class="option-group-header">
                  {{ accessory.optionGroups[index].products[0].shortDescription }}
                </h4>
              </template>
              <div class="option-group-row" v-for="product in optionGroup.products" :key="product.id">
                <div class="col-desc">
                  <img @click="toggleSpecsModal(product)" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-small.jpg'" />
                  <div class="prod-desc">
                    <span class="prod-code">{{ product.id }}</span>
                    <span class="prod-title">{{ product.shortDescription }}</span>
                    <span class="prod-subtitle">{{ product.subTitle }}</span>
                    <template v-if="screenWidth < 768">
                    <div class="del-time-mobile">
                      <span class="mobile-label" v-if="screenWidth < 768" v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
                      <span>: </span> <span v-if="product.deliveryTime.description !== undefined">{{ product.deliveryTime.description }}</span>
                    </div>
                    </template>
                  </div>
                </div>
                <div class="col-more-info">
                  <span @click="toggleSpecsModal(product)" class="material-icons-outlined">info</span>
                </div>
                <template v-if="screenWidth >= 768">
                  <div class="col-delivery">
                    <span v-if="product.deliveryTime.description !== ''">{{ product.deliveryTime.description }}</span>
                  </div>
                </template>
                <div class="col-price">
                  <utlz-product-price
                    v-if="product.prices.length"
                    :prices="product.prices[0]"                    
                    :showAdvicePrices="true"
                  ></utlz-product-price>
                </div>
                <div class="col-quantity">
                  <span>{{product.saleUnit > 0 ? product.saleUnit : 1}}</span>
                </div>
                <div class="col-cart">
                  <div class="custom-checkbox">
                    <input type="checkbox" :id="product.id" :value="product.id" :name="optionGroup.groupId" v-on:change="activateDependsOn($event, product.dependsOn, true, false, { prodCode: product.id, selectedVal: selectedValues[accessory.id].optionGroups })" v-model="selectedValues[accessory.id].optionGroups[product.id]" />           
                    <label :for="product.id"></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="option-group-row checkboxes" v-for="checkbox in accessory.checkBoxes" :key="checkbox.id">
              <div class="col-desc">
                <img  @click="toggleSpecsModal(checkbox)" :src="checkbox.imageUrl ? checkbox.imageUrl : '/documents/productimages/not-available-small.jpg'" />
                <div class="prod-desc">
                  <span class="prod-code">{{ checkbox.id }}</span>
                  <span class="prod-title">{{ checkbox.shortDescription }}</span>
                  <span class="prod-subtitle">{{ checkbox.subTitle }}</span>
                  <template v-if="screenWidth < 768">
                    <div class="del-time-mobile">
                      <span class="mobile-label" v-if="screenWidth < 768" v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>
                      <span>: </span><span v-if="checkbox.deliveryTime.description !== undefined">{{ checkbox.deliveryTime.description }}</span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-more-info">
                <span @click="toggleSpecsModal(checkbox)" class="material-icons-outlined">info</span>
              </div>
              <template v-if="screenWidth >= 768">
                <div class="col-delivery">
                  <span v-if="checkbox.deliveryTime.description !== undefined">{{ checkbox.deliveryTime.description }}</span>
                </div>
              </template>
              <div class="col-price">
                <utlz-product-price
                   v-if="checkbox.prices.length"
                  :prices="checkbox.prices[0]"
                  :showAdvicePrices="true"
                ></utlz-product-price>
              </div>
              <div class="col-quantity">
                  <span>{{checkbox.saleUnit > 0 ? checkbox.saleUnit : 1}}</span>
                </div>
              <div class="col-cart">
                <div class="custom-checkbox">
                  <input type="checkbox" :id="checkbox.id" :name="checkbox.id" v-on:change="activateDependsOn($event, checkbox.dependsOn, selectedValues[accessory.id].checkBoxes[checkbox.id], checkbox.id)" v-model="selectedValues[accessory.id].checkBoxes[checkbox.id]" />
                  <label :for="checkbox.id"></label>
                </div>
              </div>
            </div>
          </div>        
        </div>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import StaticModal from 'src/components/ui/static-modal/StaticModal.vue';
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-static-modal': StaticModal
  },
  data () {
    return {
      selectedValues: {},
      render: true,
      showSpecsModal: false,
      modalProduct: {}
    }
  },
  watch: {
    accessories : function (newVal, oldVal) {
      if (newVal.length) {              
          newVal.forEach(elem => {
            var optionGroups = [];
            var checkBoxes = []
            elem.accessoryProducts.forEach(product => {
              product.productDetails.dependsOn = product.dependsOn;
              if(product.optionGroup.length) {
                var foundOptionGroup = optionGroups.find(group => { return group.groupId === product.optionGroup; });
                if (foundOptionGroup) {
                  foundOptionGroup.products.push(product.productDetails);
                } else {
                  optionGroups.push({
                    groupId: product.optionGroup,
                    products: [product.productDetails]
                  });
                }                      
              } else {
                checkBoxes.push(product.productDetails);    
              }
            });
            elem.optionGroups = optionGroups;
            elem.checkBoxes = checkBoxes;

            var selectedOptions = {
              checkBoxes: {},
              optionGroups: {}
            };                  
            
            checkBoxes.forEach(box => {
              selectedOptions.checkBoxes[box.id] = true;
            });

            optionGroups.forEach(option => {
              // selectedOptions.optionGroups[option.groupId] = option.products[0].id;
              option.products.forEach((product, index) => {
                if (index === 0) {
                  selectedOptions.optionGroups[product.id] = true;
                } else {
                  selectedOptions.optionGroups[product.id] = false;
                }
              });              
            });

            this.selectedValues[elem.id] = selectedOptions;
          });
        }
        this.generateSelectedProducts();
      }
    },
  computed: {
      ...mapGetters(['screenWidth']),
      ...mapGetters('productDetails', ['accessories'])
  },
  methods: {
    toggleSpecsModal (product) {
      if (this.showSpecsModal) {
        this.modalProduct = {};
      } else {
        this.modalProduct = product;
      }
      this.showSpecsModal = !this.showSpecsModal;
    },
    ...mapActions('productDetails', ['setSelectedAccessories']),
    activateDependsOn (e, dependsOnProducts, checked, checkedProductId, { prodCode, selectedVal } = {}) {
      if (dependsOnProducts[0].length > 0 && checked) {
        this.accessories.forEach(accessory => {
          accessory.checkBoxes.forEach(box => {
            if (dependsOnProducts.includes(box.id)) {
              if (this.selectedValues[accessory.id].checkBoxes[box.id] === false) {                                                
                this.selectedValues[accessory.id].checkBoxes[box.id] = true;
                this.activateDependsOn(e, box.dependsOn);
              }              
            }
          });
          accessory.optionGroups.forEach(option => {
            let product = option.products.find(prod => { return dependsOnProducts.includes(prod.id); });

            if (product) {
              if (this.selectedValues[accessory.id].optionGroups[option.groupId] !== product.id) {                
                this.selectedValues[accessory.id].optionGroups[option.groupId] = product.id;
                this.activateDependsOn(e, product.dependsOn);
              }
            }            
          });
        });        
      } else if (dependsOnProducts[0].length === 0) {
        // If clicked product does not depend on another product, check if 
        // other products depend on the target product, which need to be switched off as well
          this.accessories.forEach(accessory => {
            accessory.checkBoxes.forEach(box => {
              if (box.dependsOn.includes(checkedProductId)) {
                if (!checked) {                  
                  if (this.selectedValues[accessory.id].checkBoxes[box.id] === true) {                                                
                    this.selectedValues[accessory.id].checkBoxes[box.id] = false;
                  } 
                }             
              }
            });
          });   
      }    

      if (prodCode) {

        if (e.target.checked) {
          Object.keys(selectedVal).forEach(val => {
            if (val !== prodCode) {
              selectedVal[val] = false;
            } else {
              selectedVal[val] = true;
            }
          });
        } 
      }


      this.render = false;
        this.$nextTick(() => {
          this.render = true;
        });
        this.generateSelectedProducts(); 
    },
    generateSelectedProducts() {
        let selectedProducts = [];
        Object.keys(this.selectedValues).forEach(elem => {
          var accessory = this.accessories.find(acc => { return acc.id === elem });
          Object.keys(this.selectedValues[elem].checkBoxes).forEach(box => {
            if (this.selectedValues[elem].checkBoxes[box]) {
              let product = accessory.checkBoxes.find(findBox => { return box === findBox.id});
              let selectedProduct = {
                  accessoriesId: elem,
                  productCode: box,
                  saleUnit: product.saleUnit <= 1 ? 1 : product.saleUnit,
                  originalSaleUnit: product.saleUnit <= 1 ? 1 : product.saleUnit,
                  product: product
              }
              selectedProducts.push(selectedProduct);
            }
          });
          Object.keys(this.selectedValues[elem].optionGroups).forEach(optionKey => {           
            if (this.selectedValues[elem].optionGroups[optionKey]) {
              let product = accessory.optionGroups[0].products.find(findOption => { return optionKey === findOption.id});
              let selectedProduct = {
                accessoriesId: elem,
                productCode: optionKey,
                saleUnit: product.saleUnit <= 1 ? 1 : product.saleUnit,
                originalSaleUnit: product.saleUnit <= 1 ? 1 : product.saleUnit,
                product: product
            }
            selectedProducts.push(selectedProduct);
            }            
          });
        });
        this.setSelectedAccessories(selectedProducts);
    }
  }  
}
</script>
