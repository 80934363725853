<template>
  <div class="cmp-elastic-product-details utlz-reset utlz-row">    
      <div class="utlz-col-md-4 column-left">
        <utlz-gallery :product="productDetails"></utlz-gallery>
      </div>
      <div class="utlz-col-md-8 column-right product-details-column">
          <div class="product-information">
            <!-- BESPOKE HWS: Set prod code above prod title  -->
            <div class="product-code">
                <span>{{ productDetails.id }}</span>
            </div>
            <!-- END BESPOKE HWS -->
            <h1>{{ productDetails.shortDescription }}</h1>
            
            <!-- BESPOKE HWS-86 Added :showAdvicePrices="true"-->
            <div class="ph-product-price" v-if="productDetails.prices.length">
                <utlz-product-price
                    :prices="productDetails.prices[0]"
                    :showAdvicePrices="true"
                ></utlz-product-price>
            </div>
            <!-- END BESPOKE HWS-86 -->

            <utlz-tabs :tabs="tabs">
              <template v-slot:description>
                <div class="prod-desc-container" :class="{'expandable': useCollapse, 'expanded': expanded  }">
                  <div ref="productDescription" class="prod-desc-wrapper">
                    <div class="product-long-description" v-html="productDetails.longDescription">
                    </div>
                  </div>
                </div>
                <a v-if="useCollapse" @click="expanded = !expanded" class="collapse-button theme-primary">
                  <div v-if="!expanded" class="read-more">
                    <span v-translation="{ type: 'button', code: 'link_description_read_more' }"></span>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                  <div v-if="expanded" class="read-less">
                    <span v-translation="{ type: 'button', code: 'link_description_read_less' }"></span>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </a>
              </template>

              <template v-slot:videos v-if="videos.length">
                <div class="product-videos">
                  <utlz-product-videos :videos="videos"></utlz-product-videos>
                </div>
              </template>

              <template v-slot:documents v-if="documents.length">
                <div class="product-documents">
                  <utlz-product-documents :documents="documents"></utlz-product-documents>
                </div>
              </template>

              <!-- BESPOKE HWS: Grouped specification under a tab and disabled header -->
              <template v-slot:specs>
                <div class="product-specifications">
                <!-- <h2 v-translation="{ type: 'title', code: 'title_specifications' }"></h2> -->
                <div class="spec-list">
                  <div class="spec-item" v-for="prop in productProperties" :key="prop.key">
                    <div class="spec-desc">
                      {{prop.key}}
                    </div>
                    <div class="spec-value">
                      {{prop.value}}
                    </div>
                  </div>
                </div>
              </div>
              </template>
              <!-- END BESPOKE HWS -->
            </utlz-tabs>            

            <utlz-group-dropdown :variants="productVariants" />

            <!-- BESPOKE HWS: Disabled stock -->
            <!-- <utlz-product-stock
              :product="productDetails"
              :stock="productDetails.stock"
              :stockTotal="productDetails.stock"
            ></utlz-product-stock> -->
            <!-- END BESPOKE HWS -->

            <!-- BESPOKE HWS -->
            <template v-if="isNotConfigurableProduct">
              <div class="del-time-non-configurable">                
                <span v-translation="{ type: 'label', code: 'label_delivery_date' }"></span>:
                <span class="delivery-value">{{ productDetails.deliveryTime.description }}</span>
              </div>
              <div class="order-product">
                <!-- BESPOKE HWS: Removed productDetails.prices.length so we can show 
                     prices before being logged in, while being able to order -->
                <template v-if="showPrices">
                  <utlz-order-product
                    v-if="showOrderProductControl"
                    :product="productDetails"
                    :useOrderComment="useOrderLineComments"
                    >
                  </utlz-order-product>
                </template>
                <template v-else>
                  <a :href="loginUrl" class="clickthrough-btn to-login-btn">                    
                    <span v-translation="{ type: 'button', code: 'button_login_to_order' }"></span>
                  </a>
                </template>
                <!-- END BESPOKE HWS -->

                  <transition name="fade">
                    <p v-if="!showOrderProductControl && showStock" class="out-of-stock-msg">
                      <i class="fa fa-warning"></i>
                      <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
                    </p>
                  </transition>
              </div>
            </template>
            <!-- END BESPOKE HWS -->

            <div class="favorite">
              <utlz-favorites-control v-if="showFavorites" :product="productDetails" :useDescription="true"></utlz-favorites-control>
            </div>
          </div>
      </div>

      <div class="utlz-col-12 margin-bottom-30"></div>

      <!-- BESPOKE HWS-->
      <template v-if="initConfigurations">
        <template v-if="!isNotConfigurableProduct && (showOrderProductControl)">
          <div class="product-configurator utlz-col-12">
            <div id="accessories-mobile-anchor"></div>
            <div class="utlz-row">
              <div class="utlz-col-12">
                <h2 class="title-line title-left">Stel product samen</h2>
              </div>
              <div class="utlz-col-lg-9">
                <utlz-configuration-dropdowns></utlz-configuration-dropdowns>
                <utlz-accessories></utlz-accessories>
              </div>
            
              <template v-if="retrievedAllConfigPrices">
                <div id="accessories-cart-column" class="utlz-col-md-3">
                  <utlz-configurations-cart></utlz-configurations-cart>
                </div>
              </template>
              
            </div>
          </div>
        </template>
      </template>
      <!-- END BESPOKE HWS -->

      <div v-if="showOrderProductControl && productDetails.prices.length && productVariants.length" class="utlz-col-md-12">
        <utlz-group-matrix :variants="productVariants" />
      </div>

      <div v-if="relatedProducts.upSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.upSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleUpSelling"
        ></utlz-product-carousel>
      </div>

      <div v-if="relatedProducts.crossSelling.length" class="related-products upselling utlz-col-12 ">
        <utlz-product-carousel
          :products="relatedProducts.crossSelling"
          :blockSize="relatedProductBlockSize"
          :title="titleCrossSelling"
        ></utlz-product-carousel>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductGroupMatrix from 'src/components/webshop/products/product-group/ProductGroupMatrix.vue';
import ProductGroupDropdown from 'src/components/webshop/products/product-group/ProductGroupDropdowns.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
import Gallery from 'src/components/ui/gallery/Gallery.vue';
import Tabs from 'src/components/ui/tabs/Tabs.vue';
import ProductVideos from 'src/components/webshop/products/product-videos/ProductVideos.vue';
import ProductDocuments from 'src/components/webshop/products/product-documents/ProductDocuments.vue';
import ProductCarousel from 'src/components/webshop/products/product-carousel/ProductCarousel.vue';
// BESPOKE
import ConfigurationDropdowns from './../../configurations/ConfigurationDropdowns.vue';
import Accessories from './../../accessories/Accessories.vue';
import ConfigurationsCart from '../../configurations/ConfigurationsCart.vue';
// END BESPOKE
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-order-product': OrderProduct,
    'utlz-product-stock': ProductStock,
    'utlz-group-matrix': ProductGroupMatrix,
    'utlz-group-dropdown': ProductGroupDropdown,
    'utlz-favorites-control': FavoritesControl,
    'utlz-gallery': Gallery,
    'utlz-tabs': Tabs,
    'utlz-product-carousel': ProductCarousel,
    'utlz-product-videos': ProductVideos,
    'utlz-product-documents': ProductDocuments,
    // BESPOKE
    'utlz-configuration-dropdowns': ConfigurationDropdowns,
    'utlz-accessories': Accessories,
    'utlz-configurations-cart': ConfigurationsCart
    // END BESPOKE
  },
  data () {
    return {
      expanded: false,
      productDescriptionHeight: 0,
      titleUpSelling: window.vue.translations.title.title_product_relations,
      titleCrossSelling: window.vue.translations.title.title_product_relations_cross,
      tabs: [
        { id: 'description', description: window.vue.translations.label.label_tab_product_desc, active: true, visible: false },
        { id: 'documents', description: window.vue.translations.label.label_tab_product_documents, active: false, visible: false },
        { id: 'videos', description: window.vue.translations.label.label_tab_product_videos, active: false, visible: false },
        // BESPOKE HWS
        // Added product specs as tab
        { id: 'specs', description: window.vue.translations.title.title_specifications, active: false, visible: false }
        // END BESPOKE HWS
      ]
    }
  },
  watch: {    
    longDescription (val) { this.tabs[0].visible = val.length > 0 },
    documents (val) { this.tabs[1].visible = val.length > 0; },
    videos (val) { this.tabs[2].visible = val.length > 0; },
    productProperties (val) { this.tabs[3].visible = val.length > 0; }    
  },
  computed: {
    // BESPOKE HWS
    // Added props to mapState and a seperate mapGetter for retrievedAllConfigPrices and initConfigurations
    ...mapState('productDetails', ['productDetails', 'productVariants', 'relatedProducts','documents', 'videos', 'accessories', 'configurations']),
    ...mapGetters('productDetails', ['retrievedAllConfigPrices', 'isNotConfigurableProduct', 'initConfigurations']),
    // BESPOKE HWS
    // Added language
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'useOrderLineComments', 'relatedProductBlockSize', 'language']),
    // END BESPOKE HWS
    longDescription() {
      return this.productDetails.longDescription;
    },
    // END BESPOKE
    useCollapse () {
      return this.productDescriptionHeight > 150;
    },
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.productDetails.stock !== null) {
          return this.stockLimit && this.productDetails.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    productProperties () {
      return Object.keys(this.productDetails.properties).map(x => {
        return { key: x, value: this.productDetails.properties[x] }
      });
    },
    images () {
      let images = [];

      images.push(this.productDetails.imageUrl)

      this.productDetails.images.forEach(element => {
        images.push(element);
      });

      return images
    },
    // BESPOKE HWS
    // Added loginUrl
    loginUrl() {
      return `/${this.language}/webshop/login.aspx`;
    }
    // END BESPOKE HWS
  },
  updated () {
    if (this.$refs.productDescription !== undefined) {
      this.productDescriptionHeight = this.$refs.productDescription.clientHeight;
    }
  },
  created () {
    this.initDetails();
  },
  methods: {
    ...mapActions('productDetails', ['initDetails'])
  }
}
</script>

<style>

</style>
