import axios from 'axios';
import { Product } from 'src/models/product.model';
import { getProductInformation } from 'src/services/product-service';

const state = {
  productCode: '',
  productDetails: new Product(),
  groupFields: [],
  productVariants: [],
  relatedProducts: {
    upSelling: [],
    crossSelling: []
  },
  relatedProductCodes: {
    upSelling: [],
    crossSelling: []
  },
  documents: [],
  videos: [],
  bespokeValues: {},
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  },
  // BESPOKE
  configurations: [],
  accessories: [],
  configurationQuantity: 1,
  selectedConfiguration: [],
  selectedAccessories: [],
  retrievedConfigPrices: false,
  retrievedAccPrices: false,
  hasAccessories: null,
  hasConfigurations: null,
  retrievedAllConfigPrices: false,
  orderInProgress: false,
  initConfigurations: false
  // END BESPOKE
};

const getters = {
  productCode (state) {
    return state.productCode;
  },
  groupFields (state) {
    return state.groupFields;
  },
  searchConfig (state) {
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: state.activeFilters,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  productVariantEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}/GetProductGroupInformation?language=${language}`;
  },
  relatedProducts (state) {
    return state.relatedProducts;
  },
  relatedProductCodes (state) {
    return state.relatedProductCodes;
  },
  documents (state) {
    return state.documents;
  },
  videos (state) {
    return state.videos;
  },
  bespokeValues (state) {
    return state.bespokeValues;
  },
  // BESPOKE
  accessories(state) {
    return state.accessories;
  },
  initConfigurations (state) {
    return state.initConfigurations;
  },
  configurations(state){
    return state.configurations;
  },
  selectedAccessories(state) {
    return state.selectedAccessories;
  },
  selectedConfiguration(state) {
    return state.selectedConfiguration;
  },
  retrievedAllConfigPrices (state) {
    let retrievedAccPrices = true;
    let retrievedConfigPrices = true;
    if (state.hasAccessories) {
      retrievedAccPrices = state.retrievedAccPrices ? state.retrievedAccPrices : false;
    }
    if (state.hasConfigurations) {
      retrievedConfigPrices = state.retrievedConfigPrices ? state.retrievedConfigPrices : false;
    }
    return retrievedAccPrices && retrievedConfigPrices === true;
  },
  hasAccessories () {
    return state.hasAccessories;
  },
  hasConfigurations () {
    return state.hasConfigurations;
  },  
  isNotConfigurableProduct () {
    return state.hasAccessories === false && state.hasConfigurations === false;
  },
  getConfigurationQuantity () {
    return state.configurationQuantity;
  },
  configItemTotals () {
    let totals =  0;
    state.selectedConfiguration.forEach(config => {
      totals += config.saleUnit;
    });
    state.selectedAccessories.forEach(acc => {
      totals += acc.saleUnit;
    });
    return totals;
  },
  getConfigTotals (state) {
    let totals =  0;
    state.selectedConfiguration.forEach(config => {
      totals += config.product.prices[0].rawPrice * config.saleUnit;
    });
    state.selectedAccessories.forEach(acc => {
      totals += acc.product.prices[0].rawPrice * acc.saleUnit;
    });
    return totals;
  },
  orderInProgress (state) {
    return state.orderInProgress;
  }
  // END BESPOKE
};

const actions = {
  initDetails ({ commit, dispatch }) {
    dispatch('getProductInformation', true);
  },
  updateShownProduct ({ commit, getters, dispatch, rootGetters }, productId) {
    commit('setProductCode', productId);
    dispatch('getProductInformation', false);
  },
  getProductInformation ({ commit, getters, dispatch, rootGetters }, initial) {
    let mainProductCodes = [ getters.productCode ];
    axios.post(getters.productInformationEndpoint, mainProductCodes)
      .then(res => {
        if (res.data.length > 0) {
          const product = new Product(res.data[0]);

          // BESPOKE HWS
          // DIsabled stock
          // if (rootGetters.showStock) {
          //   dispatch('getProductStock', product);
          // } else {
          //   product.setStock({ stockTotal: 0 });
          // }
          product.setStock({ stockTotal: 0 });
          // END BESPOKE HWS

          if (rootGetters.showPrices) {
            dispatch('getProductPrices', product);
          }

          if (rootGetters.showFavorites) {
            dispatch('getCustomerLists', product);
          }

          // BESPOKE HWS
          // dispatch('getProductSeoInformation', product)
          // END BESPOKE HWS
          dispatch('getProductImageSeoInformation', product)

          dispatch('genericGetProductInformation', {
            endpoint: rootGetters.productVideosEndpoint,
            productId: product.id,
            setFunction: 'setVideos'
          });

          dispatch('genericGetProductInformation', {
            endpoint: rootGetters.productDocumentsEndpoint,
            productId: product.id,
            setFunction: 'setDocuments'
          });

          // BESPOKE HWS
          // If bespokeEndpoints is undefined, do not try a 
          // post request since it will cause console errors
          if (rootGetters.bespokeProductFieldsEndpoint !== undefined) {
            dispatch('genericGetProductInformation', {
              endpoint: rootGetters.bespokeProductFieldsEndpoint,
              productId: product.id,
              setFunction: 'setBespokeValues'
            });
          }
          // END BESPOKE HWS

          dispatch('genericGetProductInformation', {
            endpoint: rootGetters.relatedProductsEndpoint,
            productId: product.id,
            setFunction: 'setRelatedProductCodes'
          });

          // BESPOKE
          dispatch('getConfigurations', {productCode: product.id, selectedValues: {}});
          dispatch('getAccessories', product.id);
          dispatch('getDeliveryTime', [product]);
          // END BESPOKE

          dispatch('getVariantCodes', product);

          commit('setProductDetails', product);

          if (!initial) {
            window.history.replaceState(product, product.shortDescription, product.targetUrl);
          }
        } else {

        }
      });
  },  
  genericGetProductInformation ({ commit, dispatch, rootGetters, getters }, callInfo) {
    axios.post(callInfo.endpoint, { productCode: callInfo.productId })
      .then(res => {
        let value = res.data.d;
        commit(callInfo.setFunction, value);

        switch (callInfo.setFunction) {
          case 'setRelatedProductCodes':
            dispatch('getRelatedProducts');
            break;
        }
      });
  },
  getRelatedProducts ({ getters, commit }) {
    if (getters.relatedProductCodes.upSelling.length) {
      getProductInformation({ prodCodes: getters.relatedProductCodes.upSelling })
        .then(res => {
          commit('setRelatedProducts', { relatedProducts: res.products, type: 'upSelling' });
        });
    }
    if (getters.relatedProductCodes.crossSelling.length) {
      getProductInformation({ prodCodes: getters.relatedProductCodes.crossSelling })
        .then(res => {
          commit('setRelatedProducts', { relatedProducts: res.products, type: 'crossSelling' });
        });
    }
  },
  getVariantCodes ({ commit, dispatch, rootGetters, getters }, product) {
    if (product.variantKey !== '') {
      const client = rootGetters.clientCode;
      const language = rootGetters.language;
      let endpoint = `${rootGetters.elasticProductsEndpoint}/GetByProductVariantKeyWithRestrictions/${product.variantKey}?language=${language}&client=${client}`;

      axios.post(endpoint, getters.searchConfig)
        .then(res => {
          if (res.data) {
            dispatch('getVariantInformation', { product: product, productCodes: res.data });
          }
        });
    }
  },
  getVariantInformation ({ commit, dispatch, rootGetters, getters }, data) {
    if (data.product.variantKey !== '') {
      let endpoint = getters.productVariantEndpoint + '&variantKey=' + data.product.variantKey;

      axios.post(endpoint, { productCodes: data.productCodes, groupValueFields: getters.groupFields })
        .then(res => {
          if (res.data) {
            const products = res.data.map(product => new Product(product));
            dispatch('getProductVariantDescriptions', products);

            if (rootGetters.showStock) {
              products.forEach((product, index) => {
                axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
                  .then(res => {
                    product.setStock(res.data.d);
                  });
              });
            } else {
              products.forEach(product => product.setStock({ stockTotal: 0 }));
            }
            dispatch('getProductVariantPrices', products);
          }
        });
    }
  },
  getProductVariantPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode;
    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];
          product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
        });
      });
  },
  getProductVariantDescriptions ({ commit, getters, dispatch, rootGetters }, products) {
    axios.post(rootGetters.productVariantDescriptionEndpoint, { productCode: getters.productCode })
      .then(res => {
        let groupInfo = res.data.d;
        if (groupInfo.groupOne.code != '') {
          let groupTwoAvail = groupInfo.groupTwo.code != ''

          products.forEach(prod => {
            if (prod.properties.hasOwnProperty(groupInfo.groupOne.code)) {
              prod.properties[groupInfo.groupOne.description] = prod.properties[groupInfo.groupOne.code];
              delete prod.properties[groupInfo.groupOne.code];
            }
            if (groupTwoAvail) {
              if (prod.properties.hasOwnProperty(groupInfo.groupTwo.code)) {
                prod.properties[groupInfo.groupTwo.description] = prod.properties[groupInfo.groupTwo.code];
                delete prod.properties[groupInfo.groupTwo.code];
              }
            }
          });

          commit('setProductVariants', products);
        }
      });
  },
  getProductSeoInformation({ commit, getters, dispatch, rootGetters }, product) {
    // BESPOKE
    let endpoint = rootGetters.bespokeEndpoints.seoInfo
    // let endpoint = rootGetters.seoInformationEndpoint + '/GetProductImageInformation';
    let productCodes = [product.id];

    axios.post(endpoint, { productCodes: productCodes, language: rootGetters.language })
      .then(res => {
        res.data.d.forEach(SeoOject => {
          product.setSeoInformation(SeoOject);
          commit('setProductDetails', product);
        });
      });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, product) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    let unit = '';
    if (product.units !== undefined && product.units !== null) {
      unit = product.units.length > 0 ? product.units[0].code : '';
    }
    priceRequestWrapper.Products = [ {
      ProductId: product.id,
      ProductGroup: product.discountGroup,
      ProductUnit: unit
    } ];

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductDetails', product);
        });
      });
  },
  getProductStock ({ commit, rootGetters }, product) {
    axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
      .then(res => {
        product.setStock(res.data.d);
        commit('setProductDetails', product);
      });
  },
  getCustomerLists ({ commit, getters, rootGetters }, product) {
    const endpoint = `${rootGetters.getProductsInListsEndpoint}?debtorId=${rootGetters.customerId}`;
    axios.post(endpoint, [getters.productCode])
      .then(res => {
        Object.keys(res.data).forEach(prodCode => {
          product.setCustomerLists(res.data[prodCode]);
          commit('setCustomerLists', product);
        });
      });
  },
      // BESPOKE
      getProductImageSeoInformation ({ commit, getters, dispatch, rootGetters }, product) {
       
        let endpoint = rootGetters.bespokeEndpoints.seoDetailInfo
        let productCode = product.id;
        axios.post(endpoint, { productCode: productCode, language: rootGetters.language })
          .then(res => {
            commit('setSeoImageInfo', res.data.d);        
          });
      },
      // END BESPOKE
  // BESPOKE
  orderSelectedProducts({ commit, dispatch, rootGetters, getters }) {
    let endpoint = rootGetters.bespokeEndpoints.orderSelectedProducts;
    let selectedProducts = [];
    let parentQuantity = getters.getConfigurationQuantity;

    getters.selectedAccessories.forEach(accessory => {
      let product = {
        productCode: accessory.productCode,
        quantity: accessory.saleUnit
      }
      selectedProducts.push(product);
    });

    getters.selectedConfiguration.forEach(config => {
      let product = {
        productCode: config.productCode,
        quantity: config.saleUnit
      }
      selectedProducts.push(product);
    });

    commit('setOrderInProgress', true);
    axios.post(endpoint, {products: selectedProducts, mainProduct: getters.productCode, parentQuantity: parentQuantity}).then(res => {
      commit('setOrderInProgress', false);
      const response = res.data.d;
      // Retrieve new shoppingcart totals
      dispatch('shoppingCart/getShoppingCartTotalAndCount', null, { root: true });

      if (response.errorMessages) {
      } else {
        // REFERS TO FUNCTION CALL IN CUSTOM.JS AS LONG AS SHOPPINGCART CONTROL IS NOT VUEJS
        window.updateShoppingCart(response.product_count, response.orderTotal, response.shoppingcartHtml);

        if (response.errorMessages) {
          window.updateErrorMessage(response.errorMessages);
        }

        var message = response.order_message;
        if (response.totalProductsAdded > 0) {
          window.updateOrderMessage(message);
        }
        window.set_shopping_cart_delete_buttons();
        // END CUSTOM.JS FUNCTION CALLS
      } 
    });
  },
  getConfigurations({ commit, dispatch, rootGetters, getters }, {productCode, selectedValues}) {
    let endpoint = rootGetters.bespokeEndpoints.bespokeService + rootGetters.language + "/configuration/" + productCode;
    axios.post(endpoint, selectedValues).then(res => {

      var configurations = res.data;

      let productCodes = [];
      
      configurations.forEach(elem => {
        Object.keys(elem.configurationOptions).forEach(x => {
          productCodes.push(x);
        });
      });

      commit('setInitConfigurations');

      if (productCodes.length) {
        getProductInformation({'prodCodes':productCodes, endpoint: ''}).then(x => {
          var resp = x;
  
          x.retrievedPrices.then(res => {
            commit('retrievedConfigPrices', true);
          });
  
          configurations.forEach(elem => {
            let configOptions = []
            Object.keys(elem.configurationOptions).forEach(y => {
              let prod = resp.products.find(product => product.id === y);
              if (elem.configurationOptions[y]) {
                prod.saleUnit = parseInt(elem.configurationOptions[y]);
              }
              configOptions.push(prod);
            });          
  
            elem.configurationProducts = configOptions;
          });
          commit('setConfigurations', configurations);        
        });
      } else {
        commit('setConfigurations', false);  
      }      

    });
  },
  setSelectedConfigurations ({ commit, dispatch, rootGetters, getters }, selectedConfiguration) {
    if (selectedConfiguration.length) {
      selectedConfiguration.forEach(x => {
        x.saleUnit = x.originalSaleUnit * getters.getConfigurationQuantity;
      });
    }
    commit('setSelectedConfigurations', selectedConfiguration);
  },
  setConfigurationQuantity ({ commit, dispatch, rootGetters, getters }, configQuantity) {
    commit('setConfigurationQuantity', configQuantity);
    dispatch('setSelectedConfigurations', getters.selectedConfiguration)
    dispatch('setSelectedAccessories', getters.selectedAccessories)
  },
  setSelectedAccessories ({ commit, dispatch, rootGetters, getters }, selectedAccessories) {
    if (selectedAccessories.length) {
      selectedAccessories.forEach(x => {
        x.saleUnit = x.originalSaleUnit * getters.getConfigurationQuantity;
      });
    }
    commit('setSelectedAccessories', selectedAccessories);
  },
  getAccessories({ commit, dispatch, rootGetters, getters }, productCode) {
    let endpoint = rootGetters.bespokeEndpoints.bespokeService + rootGetters.language + "/accessories/" + productCode;
    axios.get(endpoint).then(res => {
      var accessories = res.data;
      let productCodes = [];
      
      res.data.forEach(elem => {
        elem.accessoryProducts.forEach(x => {
          productCodes.push(x.productCode);
        });
      });

      if (productCodes.length) {

        getProductInformation({'prodCodes':productCodes, endpoint: ''}).then(x => {
          var resp = x;
          
          x.retrievedPrices.then(res => {
            commit('retrievedAccPrices', true);
          });
          
          accessories.forEach(elem => {
            elem.accessoryProducts.forEach(y => {
              let prod = resp.products.find(product => product.id === y.productCode);
              if (y.saleUnit) {
                prod.saleUnit = parseInt(y.saleUnit);
              }

              y.productDetails = prod;
            });          
          });
          commit('setAccessories', accessories);      
          
          const productDetails = [];
          
          // Get delivery time for all products
          accessories.forEach(accProduct => {
            accProduct.accessoryProducts.forEach(acc => {
              productDetails.push(acc.productDetails);
            });
          });
          dispatch('getDeliveryTime', productDetails);
        });
      } else {
        commit('setAccessories', false); 
      }
     
    });
  },
  // BESPOKE HWS
  getDeliveryTime ({ getters, rootGetters }, products) {
    const endpoint = `${rootGetters.bespokeEndpoints.bespokeService}${rootGetters.language}/delivery/GetDeliveryTime`;
    const payload = [];

    products.forEach(product => {
      payload.push({ ProductCode: product.id, quantity: 1 });
    });
    
    axios.post(endpoint, payload).then(res => {
      res.data.forEach(resObj => {
        const product = products.filter(product => product.id === resObj.key)[0];
        product.setDeliveryTime(resObj.value);
      });
    });
  }
  // END BESPOKE HWS
};

const mutations = {
  setProductCode (state, productCode) {
    state.productCode = productCode;
  },
  setProductVariants (state, productVariants) {
    state.productVariants = productVariants;
  },
  setGroupFields (state, groupFields) {
    state.groupFields = groupFields;
  },
  setSearchConfig (state, searchConfig) {
    state.searchConfig = searchConfig;
  },
  setProductDetails (state, product) {
    state.productDetails = product;
  },
  setCustomerLists (state, productWithList) {
    state.productDetails = productWithList;
  },
  setSeoImageInfo (state, imageList) {
    state.imageSeoInfo = imageList;
  },
  setRelatedProducts (state, { relatedProducts, type }) {
    state.relatedProducts[type] = relatedProducts;
  },
  setRelatedProductCodes (state, relatedProductCodes) {
    state.relatedProductCodes = relatedProductCodes;
  },
  setDocuments (state, documents) {
    state.documents = documents;
  },
  setVideos (state, videos) {
    state.videos = videos;
  },
  setBespokeValues (state, bespokeValues) {
    state.bespokeValues = bespokeValues;
  },
  // BESPOKE
  setInitConfigurations(state) {
    state.initConfigurations = true;
  },
  setConfigurations(state, configurations) {
    if (configurations) {
      state.configurations = configurations;
      state.hasConfigurations = true;
    } else {
      state.hasConfigurations = false;
    }
  },
  setSelectedConfigurations(state, selectedConfigurations) {
    state.selectedConfiguration = selectedConfigurations;
  },
  setConfigurationQuantity(state, configQuantity) {
    state.configurationQuantity = parseInt(configQuantity);
  },
  setAccessories(state, accessories) {
    state.accessories = accessories;
    if (accessories) {
      state.accessories = accessories;
      state.hasAccessories = true;
    } else {
      state.hasAccessories = false;
    }
  },
  setSelectedAccessories(state, selectedAccessories) {    
    state.selectedAccessories = selectedAccessories;
  },
  retrievedConfigPrices (state, retrieved) {
    state.retrievedConfigPrices = retrieved;
  },
  retrievedAccPrices (state, retrieved) {
    state.retrievedAccPrices = retrieved;
  },
  setOrderInProgress (state, inProgress) {
    state.orderInProgress = inProgress;
  }
  // END BESPOKE
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
