<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">
      <template v-if="product.prices != 'undefined'">
        <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
      </template>
      <div class="utlz-col-3 utlz-col-sm-12 img-container">

          <!-- BESPOKE HWS -->
          <!-- <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control> -->
          <!-- END BESPOKE HWS -->

          <template
            v-if="screenWidth >= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <figure class="img-square prod-img">
            <a :href="product.targetUrl" class="clickthrough">
              <img class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
            </a>
          </figure>
      </div>

      <div class="utlz-col-9 utlz-col-sm-12 text-container">
        <a :href="product.targetUrl" class="clickthrough">
          <!-- BESOKE HWS: Disabled product code -->
          <!-- <div class="product-code">
            <span class="value">{{ product.id }}</span>
          </div> -->
          <!-- END BESPOKE HWS -->

          <template v-if="screenWidth <= 576">
            <div class="product-banners">
              <div v-for="(banner, index) in productImageBanners" :key="'prod-banner-wrapper-' + index" :class="{ [banner.position]: true }" class="prod-banner-wrapper">
                <div v-for="(bannerVal, ind) in banner.values" :key="'prod-banner-wrapper-' + ind">
                  <span class="text-banner" v-if="bannerVal.Type === 0" :style="'background-color:' + bannerVal.Color + ';color: ' + bannerVal.TextColor">
                    {{ bannerVal.Text }}
                  </span>
                  <img class="img-banner" v-if="bannerVal.Type === 1" :src="'/' + bannerVal.Image" />
                </div>
              </div>
            </div>
          </template>

          <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
          
          <!-- BESPOKE HWS: Add prod subTitle -->
          <div class="long-desc">
            <div class="long-desc-value">{{ product.subTitle }}</div>
          </div>
          <!-- END BESPOKE HWS -->
          
        </a>

        <!-- BESPOKE: Added :showAdvicePrices="true" -->
        <div class="ph-product-price" v-if="product.prices.length">
          <utlz-product-price
            :showAdvicePrices="true"
            :prices="product.prices[0]"
            :parentClassRef="'ref-product-list-item'"
          ></utlz-product-price>
        </div>
        <!-- END BESPOKE HWS -->

        <utlz-product-variant
          v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
          :product="computedProduct"
          :productVariants="product.productVariants"
          @selectedProduct="selectedProduct($event)"
        ></utlz-product-variant>

        
        <!-- BESPOKE HWS: Show clickthrough button to product details page 
        instead of the usual order product in the product blocks, and removed prices.length condition -->
        <template v-if="showPrices">
          <div class="ph-order-product">
              <a v-if="showOrderProductControl" :href="product.targetUrl" class="clickthrough-btn">
                <i class="material-icons-outlined">shopping_cart</i>
                <span v-translation="{ type: 'button', code: 'button_order' }"></span>
              </a>
              <!-- <utlz-order-product
                v-if="showOrderProductControl && product.prices.length"
                :product="computedProduct"
                :parentClassRef="'ref-product-list-item'"
              >
              </utlz-order-product> -->
              <!-- END BESPOKE HWS -->

            <transition name="fade">
              <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
                <i class="fa fa-warning"></i>
                <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
              </p>
            </transition>
          </div>
        </template>
        <template v-else>
          <div class="ph-order-product">
            <a v-if="showOrderProductControl" :href="product.targetUrl" class="clickthrough-btn">
              <span v-translation="{ type: 'button', code: 'button_view_product' }"></span>
            </a>
          </div>
        </template>


        <!-- If product has variants, disable stock and
        use stock in OrderProduct.vue component which is variant specific -->        
        <!-- BESPOKE HWS: Disable product stock -->
        <!-- <utlz-product-stock
          v-if="!product.variantKey || groupProductsList == 0"
          :stock="product.stock"
          :stockTotal="product.stock"
        ></utlz-product-stock> -->
        <!-- END BESPOKE HWS -->

      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';
export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl
  },
  props: {
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      stockProduct: this.product.customBooleans.STOCK_PRODUCT,
      isSalesAction: this.product.prices[0],
      activeProductVariantId: null
    };
  },
  computed: {
    ...mapState('elastic', ['layoutType']),
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth', 'condenseProductList']),
    // BESPOKE HWS-88
    // Deleted if(this.showPrices) so we can show orderProduct when prices are not shown
    showOrderProductControl () {
      if (this.showOrderProduct) {
        if (!this.stockLimit || !this.stockProduct) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // END BESPOKE HWS-88
    showOutOfStockMessage () {
      if (!this.stockLimit || !this.stockProduct) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    },
    productImageBanners () {
      return this.product.productImageBanners.filter(banner => banner.show === true);
    }
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    }
  }
};
</script>

<style>
</style>
