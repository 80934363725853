<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">

    <template v-if="!showAdvicePricesTemplate">
      <ul class="price-list flex-row-nowrap align-items-baseline">
        <!-- BESPOKE HWS: Do not show price if price is 0, because it is a configurable product,
        and needs to be configured on the product detaisl page first -->
        <template v-if="prices.rawPrice > 0">
          <li class="default">
            <span>{{ prices.price }}</span>
          </li>
          <li v-if="prices.isSalesAction" class="original salesaction">
            <span>{{ prices.basePrice }}</span>
          </li>
        </template>
        <!-- END BESPOKE HWS -->
      </ul>
    </template>

    <template v-else>
      <ul class="price-list flex-row-nowrap align-items-baseline gross-price-list">
        <template>
          <li class="default">            
            <span class="price-label" v-translation="{ type: 'label', code: 'label_your_price' }"></span>
            <span class="price-actual">{{ prices.price }}</span>
          </li>          
          <li class="default gross-price">            
            <span class="price-label" v-translation="{ type: 'label', code: 'label_gross_price' }"></span>
            <span class="price-actual">{{ prices.grossPrice }}</span>
          </li>
        </template>
      </ul>
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false },
    // BESPOKE HWS
    showAdvicePrices: { type: Boolean, required: false, default: false }
    // END BESPOKEH HWS
  },
  computed: {
    ...mapGetters(['showPrices']),
    // BESPOKE HWS
    showAdvicePricesTemplate () {
      return this.showAdvicePrices && (this.prices.rawPrice < this.prices.rawGrossPrice);
    }
    // END BESPOKE HWS
  }
}
</script>

<style>

</style>
